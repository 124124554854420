export default {
    name: 'AffiliateDetails',
    components: {},
    props: [],
    data() {
        return {}
    },
    computed: {},
    async mounted() {
    },
    methods: {},
    async beforeCreate() {
    },
    watch: {}
}
