import {bus} from '@/helpers/bus';

import Utils from '@/helpers/utils';
import States from '@/helpers/states';
import Dh from '@/helpers/date.helper';

import Validations from './validation';
import Errors from './Errors';

import AgentFilter from "@/components/Filters/Agent/index";

import AffiliateService from '@/services/affiliate.service';

const service = new AffiliateService();

export default {
    name: 'AffiliateListing',

    components: {
        AgentFilter
    },

    data() {
        return {
            affiliates: [],
            affiliate: {
                FirstName: "",
                MiddleName: "",
                LastName: "",
                Email: "",
                Phone: "",
                Birthday: "",
                DriverLicense: "",
                SSN: "",
                Agent: this.$getUser()._id,

                Address: "",
                City: "",
                State: "FL",
                Zip: "",
                Apt: "",
            },

            tab: 'user_info',
            states: States,

            agentSelected: this.$route.query.agent === undefined
                ? this.$getUser()._id
                : this.$route.query.agent,

            createdModal: false,
            deleteModal: false,
            inviteModal: false,
            resendTermsModal: false,
            sendLinksModal: false,
            isLoading: false,

            searchTerm: '',
            perPage: 25,
            totalRows: 0,
            currentPage: 1,

            errors: {},
        }
    },

    validations: Validations,

    computed: {
        ...Errors,

        showAgentFilter() {
            return !!this.$hasRole('manager');
        },
    },

    async mounted() {
        await this.list();
    },

    methods: {
        async create() {
            await this.$v.$touch();
            if (this.$v.$invalid) return;

            this.isLoading = true;

            const res = await service.create(this.affiliate);
            if (res && !res.error) {
                this.closeModal();
                if (res.data && res.data.affiliate) {
                    this.affiliates.unshift(res.data.affiliate);
                }
            }

            this.isLoading = false;
        },

        async remove() {
            this.isLoading = true;

            const affiliateIdx = this.affiliates.findIndex(el => el._id === this.deleteModal);

            const res = await service.delete(this.deleteModal);
            if (res && !res.error) {
                this.closeModal();

                if (affiliateIdx > -1) {
                    this.affiliates.splice(affiliateIdx, 1);
                } else {
                    await this.list();
                }
            }

            this.isLoading = false;
        },

        async sendTerms(affiliate) {
            affiliate.TermsSentAt = 'send';

            const res = await service.sendTerms(affiliate._id);
            if (res && !res.error) {
                affiliate.TermsSentAt = new Date();
                this.closeModal();
            }
        },

        async resendTerms() {
            const affiliate = this.resendTermsModal;
            affiliate.TermsSentAt = 'send';

            this.isLoading = true;

            const res = await service.sendTerms(affiliate._id, true);
            if (res && !res.error) {
                this.isLoading = false;
                affiliate.TermsSentAt = new Date();
                this.closeModal();
            }
        },

        async sendLinks() {
            const affiliate = this.sendLinksModal;
            this.isLoading = true;

            const res = await service.sendLinks(affiliate._id);
            if (res && !res.error) {
                this.isLoading = false;
                this.closeModal();
            }
        },

        async sendInvite() {
            await this.$v.affiliate.Phone.$touch();
            if (this.$v.affiliate.Phone.$invalid) return;

            this.isLoading = true;

            const res = await service.sendInvite(this.affiliate.Phone);
            if (res && !res.error) {
                if (res.data.sent) {
                    this.closeModal();
                } else {
                    this.errors.invite = this.$t('number_taken_by_affiliate');
                }
            }

            this.isLoading = false;
        },

        async list(page) {
            this.currentPage = page || 1;
            const skip = this.perPage * (this.currentPage - 1);

            const filters = {
                search: this.searchTerm,
                agent: this.agentSelected
            }

            const res = await service.list(this.perPage, skip, filters);
            if (res && !res.error) {
                this.affiliates = res.data.affiliates;
                this.totalRows = res.data.count;
            }
        },

        closeModal() {
            this.createdModal = false;
            this.inviteModal = false;
            this.deleteModal = false;
            this.resendTermsModal = false;
            this.sendLinksModal = false;
            this.isLoading = false;
            this.errors = {};
            this.$v.$reset();
            this.resetAffiliate();
        },

        resetAffiliate() {
            this.affiliate = {
                FirstName: "",
                MiddleName: "",
                LastName: "",
                Email: "",
                Phone: "",
                Birthday: "",
                DriverLicense: "",
                SSN: "",
                Agent: this.$getUser()._id,
                Address: "",
                City: "",
                State: "FL",
                Zip: "",
                Apt: "",
            }
        },

        async setPerPage(num) {
            this.perPage = +(num);
            bus.$emit('HIDE_ALL_MODALS');
            await this.list();
        },

        highlight(text) {
            return Utils.HighlightText(text, this.searchTerm);
        },

        showResendTerms(affiliate) {
            return affiliate.TermsSentAt && !affiliate.TermsAcceptedAt;
        },

        showSendLinks(affiliate) {
            if (affiliate.TermsAcceptedAt) {
                const index = affiliate.Links.findIndex(el => el.Agent === this.$getUser()._id)
                return index > -1;
            }
            return false;
        },

        async onSelectAgent(agentId) {
            if (this.agentSelected !== agentId) {
                this.agentSelected = agentId;
                await this.replaceUrlQuery();
                await this.list();
            }
        },

        async replaceUrlQuery() {
            await this.$router.replace({
                query: {
                    agent: this.agentSelected,
                }
            })
        },

        next() {
            this.list(this.currentPage + 1);
        },

        prev() {
            this.list(this.currentPage - 1);
        },

        formatUSNumber(number) {
            if (number) {
                return Utils.formatUSNumber(number);
            }
            return 'N/A';
        }
    },

    watch: {
        perPage() {
            this.list();
        },

        searchTerm() {
            this.list();
        },

        'affiliate.Phone'() {
            this.errors.invite = '';
        }
    },

    filters: {
        termsAcceptDate(value) {
            if (value) return `Accepted ${Dh.formatDate(value)}`;
            return 'N/A'
        },

        termsSentDate(value) {
            if (value) return `Sent ${Dh.formatDate(value)}`;
            return 'N/A'
        },

        createdDate(value) {
            if (value) return Dh.formatDate(value);
            return 'N/A'
        },

        phoneFormat(value) {
            if (value) return Utils.formatUSNumber(value)
            return 'N/A'
        },

        agentsName(values) {
            if (values.length) {
                return values.map(el => `${el.FirstName} ${el.LastName}`).join(', ');
            }
            return 'N/A'
        }
    }
}