import {required, minLength, email} from 'vuelidate/lib/validators';

import Dh from '@/helpers/date.helper';

const // Custom validators
    isName = value => (/^([A-Za-z\s]+)$/i.test(value)),
    isSSN = value => (/^(?!000|666)[0-8][0-9]{2}-?(?!00)[0-9]{2}-?(?!0000)[0-9]{4}$/.test(value)),
    isMobilePhone = value => (/^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/.test(value)),
    isZipCode = value => (/^\d{5}(-\d{4})?$/.test(value));

export default {
    affiliate: {
        FirstName: {
            required,
            isName,
        },

        LastName: {
            required,
            isName,
        },

        Email: {
            email,
            required
        },

        Phone: {
            isMobilePhone,
            required
        },

        Birthday: {
            maxDate: date => {
                return date ? Dh.isBefore(date, new Date()) : true;
            }
        },

        SSN: {
            SsnIf: ssn => ssn ? isSSN(ssn) : true,
        },

        DriverLicenseState: {
        },

        DriverLicense: {
        },

        Address: {
            minLength: minLength(4),
        },

        City: {
            minLength: minLength(4),
        },

        State: {
            minLength: minLength(2),
        },

        Zip: {
            ZipIf: zip => zip ? isZipCode(zip) : true
        },
    },
}