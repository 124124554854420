export default {
    FirstNameError() {
        if (!this.$v.affiliate.FirstName.$anyError) return null;
        else if (!this.$v.affiliate.FirstName.required) return "required_field";
        else if (this.$v.affiliate.FirstName.$invalid) return "invalid_field";
        else return null;
    },

    LastNameError() {
        if (!this.$v.affiliate.LastName.$anyError) return null;
        else if (!this.$v.affiliate.LastName.required) return "required_field";
        else if (this.$v.affiliate.LastName.$invalid) return "invalid_field";
        else return null;
    },

    EmailError() {
        if (!this.$v.affiliate.Email.$anyError) return null;
        else if (!this.$v.affiliate.Email.required) return "required_field";
        else if (this.$v.affiliate.Email.$invalid) return "invalid_field";
        else return null;
    },

    PhoneError() {
        if (!this.$v.affiliate.Phone.$anyError) return null;
        else if (!this.$v.affiliate.Phone.required) return "required_field";
        else if (this.$v.affiliate.Phone.$invalid) return "invalid_field";
        else return null;
    },

    BirthdayError() {
        if (!this.$v.affiliate.Birthday.$anyError) return null;
        else if (this.$v.affiliate.Birthday.$invalid) return "invalid_field";
        else return null;
    },

    SSNError() {
        if (!this.$v.affiliate.SSN.$anyError) return null;
        else if (this.$v.affiliate.SSN.$invalid) return "invalid_field";
        else return null;
    },

    DriverLicenseError() {
        if (!this.$v.affiliate.DriverLicense.$anyError) return null;
        else if (this.$v.affiliate.DriverLicense.$invalid) return "invalid_field";
        else return null;
    },

    AddressError() {
        if (!this.$v.affiliate.Address.$anyError) return null;
        else if (this.$v.affiliate.Address.$invalid) return "invalid_field";
        else return null;
    },

    CityError() {
        if (!this.$v.affiliate.City.$anyError) return null;
        else if (this.$v.affiliate.City.$invalid) return "invalid_field";
        else return null;
    },

    StateError() {
        if (!this.$v.affiliate.State.$anyError) return null;
        else if (this.$v.affiliate.State.$invalid) return "invalid_field";
        else return null;
    },

    ZipError() {
        if (!this.$v.affiliate.Zip.$anyError) return null;
        else if (this.$v.affiliate.Zip.$invalid) return "invalid_field";
        else return null;
    },
};